// Core polyfills and compatibility.
import "core-js/stable";
import "regenerator-runtime/runtime";

// Rails Libraries
import * as ActiveStorage from "@rails/activestorage";

import "bootstrap";

// App Libraries
import "../lib/bootstrap-filestyle";
import "../lib/input-mask";
import "../lib/submissions";

// Static Assets
import "../stylesheets/application";

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);